import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Modal, Segment } from 'semantic-ui-react';
import _ from 'lodash';

class MediaGalleryModal extends Component {
  static get propTypes() {
    return {
      data: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
      ]),
    }
  }

  constructor(props) {
    super(props);

    this.handlerOnMount = this.handlerOnMount.bind(this);
    this.renderMediaCollection = this.renderMediaCollection.bind(this);
    this.renderMediaItem = this.renderMediaItem.bind(this);

    this.state = {
      loading: false,
      media: {},
    };
  }

  componentDidMount() {
    this.handlerOnMount();
  }

  async handlerOnMount() {
    const { data } = this.props;

    if (_.isFunction(data)) {
      // Call the function if passed a function
      this.setState({ loading: true });

      const media = await data();

      this.setState({
        media,
        loading: false,
      });
    } else {
      // Initialize media from data if passed an object
      this.setState({ media: data });
    }
  }

  renderMediaItem({ uuid, file_name, url, mime_type }) {
    const preview = _.startsWith(mime_type, 'image')
      ? url
      : `https://dummyimage.com/300x300/fefefe/888888.png&text=${file_name}`;

    return (
      <Image
        bordered
        key={uuid}
        src={preview}
        as="a"
        href={url}
        target="_blank"
      />
    );
  }

  renderMediaCollection(media, collection) {
    const { loading } = this.state;

    return (
      <Segment loading={loading} key={collection}>
        <p>{_.capitalize(_.replace(_.kebabCase(collection), /-/g, ' '))}</p>
        <Image.Group size="small">
          {_.map(media, this.renderMediaItem)}
        </Image.Group>
      </Segment>
    );
  }

  render() {
    const { media } = this.state;

    const headerText = _.get(this.props, 'headerText', 'File Upload');
    const actionLabel = _.get(this.props, 'actionLabel', 'Upload File');
    const description = _.get(this.props, 'description', 'Select a File');

    return (
      <Modal
        closeIcon
        trigger={<Button fluid content={actionLabel} />}
        onMount={this.handlerOnMount}
      >
        <Modal.Header>{headerText}</Modal.Header>
        <Modal.Content>
          <p>{description}</p>

          {_.map(media, this.renderMediaCollection)}
        </Modal.Content>
      </Modal>
    );
  }
}

export default MediaGalleryModal;
