/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import {
  Container,
  List,
} from 'semantic-ui-react';

class AboutPage extends PureComponent {
  render() {
    return (
      <div className="centeredFullPageContainer">
        <Container>
          <h1>Platform Details</h1>
          <List>
            <List.Item>
              <List.Content>
                {process.env.NODE_ENV}
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                {process.env.REACT_APP_API_BASE_URL}
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </div>
    );
  }
}

export default AboutPage;
