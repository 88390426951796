import React, { PureComponent } from 'react';
import {
  Container,
} from 'semantic-ui-react';

class LandingPage extends PureComponent {
  render() {
    return (
      <div className="centeredFullPageContainer">
        <Container>
          <h1>Welcome to your online modern clinic</h1>
          <p>
            <strong>MedAlert eConsult</strong> enables you to continue seeing your patients through online video. <br />
            It is fully integrated with your <strong>MedAlert Clinic v5</strong> app, to provide you with complete convenience
          </p>
        </Container>
      </div>
    );
  }
}

export default LandingPage;
