/* eslint-disable no-undef */

export const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
  webBaseUrl: `${process.env.REACT_APP_API_BASE_URL}`,

  timeout: 10000,

  endpoints: {
    sessionDetails: 'online-services/econsults/video-sessions',
    sessionTokens: 'online-services/econsults/video-session-tokens',
    paymentUpload: 'online-services/payments',
    attachmentUpload: 'online-consults/appointments',
  },
};
