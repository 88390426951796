import axios from 'axios';
import _ from 'lodash';

import { API_CONFIG } from './config';

export const getApiClient = ({
  token,
  baseURL = API_CONFIG.baseUrl,
  timeout = API_CONFIG.timeout,
} = {}) => {
  // Initialize config options
  const config = {
    baseURL,
    timeout,
  };

  if (!_.isEmpty(token)) {
    // Automatically add token for authenticated clients
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return axios.create(config);
};
