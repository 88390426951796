import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {
  Container,
  Menu,
  Image,
} from 'semantic-ui-react';

import AboutPage from './modules/AboutPage';
import LandingPage from './modules/LandingPage';
import SessionPage from './modules/Session/SessionPage';

import Logo from './assets/images/medalert-no-text.png';

import 'semantic-ui-css/semantic.min.css'
import './App.scss';

class App extends Component {
  render() {
    return (
      <Router>
        <Menu borderless fixed="top">
          <Container>
            <Menu.Item
              as={Link}
              to="/"
              header
            >
              <Image
                src={Logo}
                size="mini"
                style={{ marginRight: '1em' }}
              />
              eConsult
            </Menu.Item>
          </Container>
        </Menu>

        <Switch>
          <Route path="/session/:appointmentId/:appointmentCode" component={SessionPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
