import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Icon,
  List,
  Segment,
  Label,
} from 'semantic-ui-react';
import _ from 'lodash';

import { getApiClient } from '../api';

class FileUploader extends PureComponent {
  static get propTypes() {
    return {
      fileField: PropTypes.string,
      uploadEndpoint: PropTypes.string.isRequired,
      uploadPayload: PropTypes.object,
      uploadCallback: PropTypes.func,
    }
  }

  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.renderFileItem = this.renderFileItem.bind(this);

    this.state = {
      pendingFiles: [],
      fileCount: 0,
      uploading: false,
      message: null,
    };
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.uploadFiles();
  }

  onChange(e) {
    const { pendingFiles } = this.state;

    const updatedPendingFiles = [
      ...pendingFiles,
      ...e.target.files,
    ];

    this.setState({
      pendingFiles: updatedPendingFiles,
      fileCount: pendingFiles.length,
    });

    e.target.value = "";
  }

  uploadFiles() {
    const { pendingFiles } = this.state;
    const {
      fileField = 'file',
      uploadEndpoint,
      uploadPayload = {},
      uploadCallback = null,
    } = this.props;

    this.setState({ uploading: true });

    const formData = new FormData();

    // Add extra payload
    for (let property in uploadPayload) {
      if (Object.prototype.hasOwnProperty.call(uploadPayload, property)) {
        formData.append(property, uploadPayload[property]);
      }
    }

    // Add files
    for (let i = 0; i < pendingFiles.length; i++) {
      formData.append(`${fileField}[]`, pendingFiles[i]);
    }

    // Use axios to refresh data from api
    getApiClient()
      .post(uploadEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(({ data }) => {
          if (_.isFunction(uploadCallback)) {
            uploadCallback(data);
          }

          this.setState({
            pendingFiles: [],
            message: 'Success! Files uploaded',
          });
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
  }

  renderFileItem({ name }) {
    return (
      <List.Item
        icon="attach"
        key={name}
        content={name}
      />
    )
  }

  render() {
    const { message, pendingFiles, uploading } = this.state;
    const description = _.get(this.props, 'description', 'Select files for upload');
    const uploadLabel = _.get(this.props, 'uploadLabel', 'Submit Files');

    return (
      <Segment basic loading={uploading}>
        <p>{description}</p>

        <Form onSubmit={this.onFormSubmit}>
          <Form.Input
            type="file"
            onChange={this.onChange}
            multiple
            disabled={uploading}
          />

          { pendingFiles.length > 0
            ? (
              <List divided relaxed size="large">
                {_.map(pendingFiles, this.renderFileItem)}
              </List>
            )
            : null
          }

          <Button
            icon
            labelPosition="left"
            disabled={uploading || pendingFiles.length === 0}
            type="submit"
          >
            <Icon name="cloud upload" />
            {uploadLabel}
          </Button>

          { message
              ? (
                <Label
                  pointing="left"
                  onClick={() => this.setState({ message: null })}
                >
                  {message}
                </Label>
              )
              : null
          }
        </Form>
      </Segment>
    );
  }
}

export default FileUploader;
