import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import _ from 'lodash';

import FileUploader from './FileUploader';

const FileUploadModal = (props) => {
  const headerText  = _.get(props, 'headerText', 'File Upload');
  const actionLabel = _.get(props, 'actionLabel', 'Upload File');
  const description = _.get(props, 'description', 'Select a File');

  const uploadEndpoint  = _.get(props, 'uploadEndpoint');
  const uploadPayload   = _.get(props, 'uploadPayload', {});
  const uploadCallback  = _.get(props, 'uploadCallback');
  const fileField       = _.get(props, 'fileField', 'file');

  return (
    <Modal
      closeIcon
      trigger={<Button fluid content={actionLabel} />}
    >
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>
        <FileUploader
          description={description}
          uploadLabel={actionLabel}
          uploadEndpoint={uploadEndpoint}
          uploadPayload={uploadPayload}
          uploadCallback={uploadCallback}
          fileField={fileField}
        />
      </Modal.Content>
    </Modal>
  );
}

export default FileUploadModal;
