import React, { PureComponent } from 'react';
import OpenViduSession from 'openvidu-react';
import Bugsnag from '@bugsnag/js';
import {
  Button,
  Checkbox,
  List,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { getApiClient, API_CONFIG } from '../../api';
import FileUploadModal from '../../components/FileUploadModal';
import MediaGalleryModal from '../../components/MediaGalleryModal';

import './SessionPage.scss';

class SessionPage extends PureComponent {
  constructor(props) {
    super(props);

    this.getAppointmentDetails = this.getAppointmentDetails.bind(this);
    this.getSessionToken = this.getSessionToken.bind(this);
    this.initializeSessionPage = this.initializeSessionPage.bind(this);
    this.reloadAppointmentDetails = this.reloadAppointmentDetails.bind(this);
    this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
    this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
    this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
    this.handlerAttachmentUpload = this.handlerAttachmentUpload.bind(this);
    this.handlerPaymentUpload = this.handlerPaymentUpload.bind(this);
    this.getFileList = this.getFileList.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);

    this.state = {
      appointment: null,
      session: null,
      token: null,

      user: {
        uuid: null,
        displayName: 'MedAlert_' + Math.floor(Math.random() * 100),
      },

      loadingDetails: true,
      connectingToSession: true,
      hasAgreedToPrivacyNotice: false,
      showPrivacyNotice: false,
    }
  }

  componentDidMount() {
    this.initializeSessionPage();
  }

  getAppointmentDetails() {
    const appointmentId = _.get(this.props, 'match.params.appointmentId');
    const appointmentCode = _.get(this.props, 'match.params.appointmentCode');

    return getApiClient().post(API_CONFIG.endpoints.sessionDetails, {
      appointment: {
        uuid: appointmentId,
        code: appointmentCode,
      },
    }).then(response => _.get(response, 'data.data'));
  }

  getSessionToken({ sessionId }) {
    return getApiClient().post(API_CONFIG.endpoints.sessionTokens, {
      session_id: sessionId,
    }).then(response => _.get(response, 'data.data'));
  }

  initializeSessionPage() {
    this.getAppointmentDetails()
      .then(({ appointment, session }) => {
        // Save appointment and session details to state

        this.setState({
          appointment,
          session,
          showPrivacyNotice: true,
        });

        return session;
      })
      .then(this.getSessionToken)
      .then(({ token }) => {
        // Save token to state
        this.setState({
          token,
          loadingDetails: false,
        })
      })
      .catch((e) => {
        Bugsnag.notify(e);
        alert('Cannot load the appointment at this time. Please try again later.');
      });
  }

  reloadAppointmentDetails() {
    return this.getAppointmentDetails()
      .then(({ appointment, session }) => {
        // Save appointment and session details to state
        this.setState({
          appointment,
          session,
        });

        return appointment;
      })
      .catch((e) => {
        Bugsnag.notify(e);
      });
  }

  handlerJoinSessionEvent() {
    //
  }

  handlerLeaveSessionEvent() {
    this.setState({
      token: null,
    });
  }

  handlerErrorEvent() {
    //
  }

  handlerAttachmentUpload(response) {
    const appointment = _.get(response, 'data', this.state.appointment);
    const updatedAppointment = {
      ...appointment,
    };

    this.setState({ appointment: updatedAppointment });
  }

  handlerPaymentUpload(response) {
    const { appointment } = this.state;
    const payment = _.get(response, 'data', _.get(appointment, 'payment', {}));

    const updatedAppointment = {
      ...appointment,
      payment,
    };

    this.setState({ appointment: updatedAppointment });
  }

  async getFileList() {
    const appointment = await this.reloadAppointmentDetails();

    this.setState({
      appointment: {
        ...appointment,
      }
    });

    return {
      proof_of_payment: _.get(appointment, 'payment.proof_of_payment', []),
      attachments: _.get(appointment, 'attachments', []),
    };
  }

  renderSidebar() {
    const { appointment } = this.state;

    const appointmentDate = _.get(appointment, 'approved_schedule');
    const localAppointmentDate = appointmentDate
      ? DateTime.fromSQL(appointmentDate, { zone: 'UTC' })
      : null;

    const paymentDetails = _.get(appointment, 'payment');

    const files = {
      proof_of_payment: _.get(appointment, 'payment.proof_of_payment', []),
      attachments: _.get(appointment, 'attachments', []),
    };

    const fileCount = files.proof_of_payment.length + files.attachments.length;

    return (
      <div className="sidebar">
        <h3>Appointment Details</h3>

        <Segment color="orange">
          <List>
            {appointmentDate
              ? (
                <List.Item>
                  <List.Icon name="calendar alternate outline" />
                  <List.Content>
                    {localAppointmentDate
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_FULL)
                    }
                  </List.Content>
                </List.Item>
              )
              : null
            }
          </List>
        </Segment>

        { fileCount
          ? (
            <Segment>
              <List>
                <List.Item>
                  <List.Icon name="images outline" />
                  <List.Content>
                    {`${fileCount} files attached to appointment`}
                  </List.Content>
                </List.Item>
              </List>

              <MediaGalleryModal
                headerText="Gallery"
                actionLabel="View Submitted Files"
                description="Uploaded files for this consultation"
                data={this.getFileList}
              />
            </Segment>
          )
          : null
        }

        { paymentDetails
          ? (
            <Segment>
              <List>
                <List.Item>
                  <List.Icon name="credit card outline" />
                  <List.Content>
                    Kindly settle your payment of <small>PHP</small> {parseFloat(paymentDetails.net_amount).toFixed(2)} before the consultation
                  </List.Content>
                </List.Item>
              </List>

              <FileUploadModal
                headerText="Proof of Payment"
                actionLabel="Submit Proof of Payment"
                description="Select an image of your deposit slip or funds transfer confirmation"
                fileField="proof_of_payment"
                uploadEndpoint={API_CONFIG.endpoints.paymentUpload}
                uploadPayload={{
                  uuid: paymentDetails.uuid,
                  payable_id: appointment.uuid
                }}
                uploadCallback={this.handlerPaymentUpload}
              />
            </Segment>

          )
          : null
        }

        <Segment>
          <List>
            <List.Item>
              <List.Icon name="attach" />
              <List.Content>
                Send any files such as pictures of lab results that you would like the doctor to see
              </List.Content>
            </List.Item>
          </List>

          <FileUploadModal
            headerText="Additional Files"
            actionLabel="Send File"
            description="Send any files such as pictures of lab results that you would like the doctor to see"
            fileField="attachments"
            uploadEndpoint={`${API_CONFIG.endpoints.attachmentUpload}/${appointment.uuid}/${appointment.code}`}
            uploadCallback={this.handlerAttachmentUpload}
          />
        </Segment>
      </div>
    );
  }

  render() {
    const {
      hasAgreedToPrivacyNotice,
      showPrivacyNotice,
      token,
      user,
    } = this.state;

    return (
      <div id="SessionPage">
        { token
          ? (
            <>
              <OpenViduSession
                id="opv-session"
                sessionName="eConsult"
                user={user.displayName}
                token={token}
                joinSession={this.handlerJoinSessionEvent}
                leaveSession={this.handlerLeaveSessionEvent}
                error={this.handlerErrorEvent}
              />
              {this.renderSidebar()}
            </>
          )
          : (
            <div className="loaderContainer">
              Loading session details...
            </div>
          )
        }
        <Modal
          open={showPrivacyNotice}
        >
          <Modal.Header>Patient Data Privacy Consent Form</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p>
                The Doctor  complies with Republic Act No. 10173 or the Data Privacy Act of 2012 (DPA) and its Implementing Rules and Regulations (IRR). In this Privacy Agreement, The Doctor hereby informs you, the patient, on how your personal information and medical prescription records will be protected. In case of any complaint regarding your privacy, The Doctor  hereby undertakes to settle the same immediately. This privacy statement covers:
              </p>
              <p>
                <ol>
                  <li>The Doctor ’s Contact Information;</li>
                  <li>The Doctor ’s identity;</li>
                  <li>Information to be collected;</li>
                  <li>How the Information will be used;</li>
                  <li>Who will have access on the information;</li>
                  <li>Safekeeping of Collected Information;</li>
                  <li>Accessing, updating, correcting, and withdrawal of information; and,</li>
                  <li>Privacy Complaint.</li>
                </ol>
              </p>
              <h3>1. Information to be collected</h3>
              <p>
                For The Doctor  to have a database of your medical history, to legally transmit the details of your prescription to partner pharmacies through the MedAlert app, and to better improve the services of the MedAlert App, through your doctor, your Patient Information and Medical Prescription Information (Patient Information) may be collected. And with this Privacy Agreement, you hereby give your consent for The Doctor  to collect your Patient Information.
              </p>
              <p>
                Patient Information within the context of this Data Privacy Consent Form includes: Name, Birthdate, Gender, Mobile Number.
              </p>
              <p>
                Below are the instances when The Doctor  will be collecting your Patient Information, such as, but not limited to your Medical Prescription Information:
              </p>
              <ol type="i">
                <li>
                  <p>
                    By availing of the services, primarily through the MedAlert Clinic App, which will be used to transmit your prescription order to partner-pharmacies, The Doctor  may collect your information when:
                  </p>
                  <ol type="a">
                    <li>
                      you fill up your personal information such as your Name, Age, Gender, Birthdate, Mobile Number, Home Address, Delivery Address;
                    </li>
                    <li>
                      you fill up your personal information in creating an account to Patient app such as your Name, Email Address, Mobile Number, Birthdate, Permanent Address, Delivery Address;
                    </li>
                    <li>
                      you fill up your personal information in ordering online through MedAlert platform such as your Name, Email Address, Mobile Number, Delivery Address, Payment Details;
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                When you refuse or withdraw your Patient Information, The Doctor  cannot extend the services benefits of the MedAlert Clinic App to you.
              </p>
              <h3>2. How the Information will be used</h3>
              <p>
                In general, The Doctor  will be using your Patient Information in order to provide you with the best service with the help of our MedAlert Clinic App. Specifically, The Doctor  will use your personal data to:
              </p>
              <ol type="i">
                <li>track patient record by clinic staff, and by doctor for medical diagnosis;</li>
                <li>track patient record for possible medicine delivery;</li>
                <li>track patient record for possible billing integration;</li>
                <li>track patient record by clinic staff and for sending relevant notifications.</li>
                <li>send information materials;</li>
                <li>account creation;</li>
                <li>display clinic contact information to patients;</li>
                <li>process your order for medicines and drugs for its fulfillment at our partner pharmacy/ies.</li>
                <li>assess the need to update the MedAlert App to better serve other patients.</li>
              </ol>
              <h3>3. Who will have access on the information</h3>
              <p>
                The information gathered will only be shared with third parties as allowed by law and when amply covered by a contractual and confidentiality agreement. These third parties are as follows:
              </p>
              <ol type="i">
                <li>The Doctor , including the clinic assistant, nurse, and/or secretary.</li>
                <li>The partner-organization/s who will satisfy your medicine and drugs purchases as prescribed by The Doctor .</li>
                <li>Unexus Medical Solutions, Inc. (UMSI) as the electronic data management provider.</li>
                <li>Government bodies and entities, pursuant to law or lawful order addressed to The Doctor  and/or UMSI, such as when we address regulatory inquiries or complaints. </li>
                <li>Other people that you have identified that we can contact, if any. </li>
                <li>Other businesses or people such as lawyers, service providers, and IT people who assist us in our business, on an as-needed basis. </li>
                <li>In emergency situations such as pandemics or instances where Healthcare workers require information, Unexus Medical Solutions, Inc. will grant temporary access to relevant information contained in one's personal data.</li>
              </ol>
              <h3>4. Safekeeping of Collected Information</h3>
              <p>
                The Doctor  complies with the Philippines’ Data Privacy Act of 2012 and its Implementing Rules and Regulations. The Doctor  complies with the National Privacy Commission (NPC) directive on keeping Personal Data safe by ensuring that organizational, physical, and technical securities are in place. The Doctor  will only keep your information for as long as needed by The Doctor  and UMSI for medical, business, or legal purposes. As soon as the stated purposes have been served, The Doctor  will take the necessary steps to destroy, de-identify, or delete your information.
              </p>
              <p>
                Organizational Security – The Doctor  and his/her clinic staff, including UMSI as the electronic data management provider, are trained in keeping the collected information safe, secured, and confidential
              </p>
              <p>
                Physical Security – The Doctor  shall make sure that your patient information are kept in utmost confidence by limiting the access to the clinic tablets being used to collect and store your patient information. In addition, UMSI, the electronic data management provider also conducts its business in a secure building with well-placed security cameras in a secured office and working with trusted individuals. UMSI also prevents unauthorized access to its work areas by locking the office door and the device cabinet is locked in key.
              </p>
              <p>
                Technical Security – UMSI, the data management provider, has an Information System Security in place. UMSI has firewalls, intrusion detection, and virus scanning tools to prevent viruses and unauthorized people from accessing its system. UMSI encrypts data and uses passwords when it sends data to authorized persons or entities. UMSI also uses database encryption to local devices. UMSI uses SSL for encrypting data in transit. Data stored at rest in the AWS servers. Using Samsung Knox on Doctor’s devices, UMSI can enforce security policies such as required passwords and device encryption.
              </p>
              <h3>5. Accessing, updating, correcting, and withdrawal of information</h3>
              <p>
                Patient Information given to The Doctor  and UMSI, through the MedAlert Clinic App, may be accessed through the patient app. Patient can download the Patient App and create an account to access his/her information. The Doctor ’s contact information is provided under Section 2 of this consent form.
              </p>
              <p>
                Viewing your information is generally free, but in case of any request for a hard copy of any information or in a CD form, UMSI will be charging a fee for the recording and/or printing of the requested information, including photocopying or printing expenses.
              </p>
              <p>
                In cases of any possible correction or request for deletion of the personal information given to UMSI, you can email or call The Doctor  to ask him/her to correct, update, or delete your information. The Doctor  shall immediately comply with such request within a reasonable time at no cost. In cases when it was determined that the information already within The Doctor ’s possession was correct and/or updated, The Doctor  reserves the right to not comply with the request but will give feedback on the grounds for refusal. In such a case, you can send a letter reiterating your request and stating your grounds for such.
              </p>
              <h3>6. Privacy Complaint</h3>
              <p>
                In such cases of complaints filed with The Doctor  regarding privacy through email, The Doctor  shall rectify such a problem right away. However, when such complaint was not satisfactorily acted upon, you can send The Doctor  a letter complaint detailing the personal information or medical prescription information involved and the particular violation committed against you with regard to such information. Immediately, The Doctor  hereby undertakes to act on such complaint at the soonest possible time upon receipt of the letter complaint, while also sending a feedback to the complainant. The Doctor  shall answer the complaint within 15 days or respond by asking for more time to study, investigate, or consult a legal counsel, if necessary. In a rare case that you will find the action on your complaint unsatisfactory, you can contact the National Privacy Commission with their office address at 3rd Floor, Core G, GSIS Headquarters Bldg., Financial Center, 1308, Pasay, Metro Manila from 8:30AM–5PM or you can call them at Telephone No.: (02) 517 7810 for further action on your complaint. For everyone’s convenience, we would encourage you to give The Doctor  enough time to respond to your queries and/or complaints.
              </p>
              <p>
                By clicking the checkbox in this Patient Data Privacy Consent Form, I hereby confirm and affirm that the contents of this form were explained to me and I understood everything that was explained to me. Further, I hereby authorize The Doctor  or any of his/her clinic staff to collect, use, process, and whenever necessary, disclose to its data electronic data management provider UMSI, service providers, subsidiaries, or affiliates my Patient Information, as provided under this Data Privacy Consent Form, exclusively for the declared purposes herein in accordance with R.A. No. 10173 (“Data Privacy Act of 2012”)
              </p>
              <Checkbox
                checked={hasAgreedToPrivacyNotice}
                label="I agree to the processing of my personal data in adherence with the Patient Data Privacy Consent Form"
                onClick={() => this.setState({ hasAgreedToPrivacyNotice: !hasAgreedToPrivacyNotice })}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Proceed"
              disabled={!hasAgreedToPrivacyNotice}
              onClick={() => this.setState({ showPrivacyNotice: false })}
              positive
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default SessionPage;
